import styled from 'styled-components';

import { Flex, Spacer, getColor, getSpace, Typography } from '@aircall/tractor-v2';
import { DESKTOP_DEVICE } from '@constants/responsive.contants';

export const Signup = styled(Spacer)`
  position: absolute;
  right: ${getSpace('xxl')};
  bottom: ${getSpace('xl')};
`;

export const LoadingContainer = styled(Flex)`
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  inset: 0;
`;

export const LayoutWrapper = styled(Flex)`
  a {
    color: ${getColor('text-base')};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const LeftWrapper = styled(Flex)`
  flex: 1 1 50%;
  align-items: center;
  justify-content: center;

  @media (max-width: ${DESKTOP_DEVICE}) {
    display: none;
  }
`;

export const RightWrapper = styled(Flex)`
  position: relative;
`;

export const ButtonWrapper = styled.div`
  margin-top: ${getSpace('l')};

  > button {
    width: 100%;
  }
`;

export const FormWrapper = styled.div`
  width: 80%;
  max-width: 400px;
`;

export const BackgroundImage = styled(Flex)<{ imagePath: string }>`
  height: 100%;
  flex: 1;
  padding: 90px 0 0 100px;
  background-image: ${({ imagePath }) => `url(/assets/${imagePath}.png)`};
  background-position: right;
  background-size: cover;
`;

export const ImageWrapper = styled.div`
  max-width: 512px;

  > svg {
    max-width: 100%;
  }

  > div:first-child svg {
    margin-bottom: ${getSpace('xl')};
    margin-left: ${getSpace('xl')};
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${getColor('divider')};
`;

export const Or = styled(Typography)`
  position: absolute;
  padding: ${getSpace('xxs')};
  background-color: white;
`;
