import { RequireUrlParamsProps } from './RequireUrlParams.decl';

import { useQueryParams } from '@hooks/useQueryParams';
import { Navigate } from 'react-router-dom';

export function RequireUrlParams({ params, redirectTo, children }: RequireUrlParamsProps) {
  const { searchParams } = useQueryParams();

  if (params.some((param) => !searchParams.get(param))) {
    return <Navigate to={redirectTo} />;
  }

  return children;
}
