import {
  BackgroundImage,
  ImageWrapper,
  LayoutWrapper,
  LeftWrapper,
  RightWrapper,
} from './Login.styles';

import { DynamicAssetComponent } from '@components/DynamicAssetComponent';
import { ResellerConfig } from '@config/resellers.config';

interface LoginLayoutProps {
  children: React.ReactNode;
  'data-test'?: string;
}

export function LoginLayout({ children, 'data-test': dataTestId }: LoginLayoutProps) {
  const {
    featureSet: { shouldShowLoginBackgroundImage },
  } = ResellerConfig;

  return (
    <LayoutWrapper w='100%' h='100%' data-test={dataTestId}>
      <LeftWrapper backgroundColor='primary-100' data-test='authlayout-lefttwrapper'>
        {shouldShowLoginBackgroundImage ? (
          <BackgroundImage
            data-test='layout-background-image'
            imagePath={ResellerConfig.getAssetPath('login_background')}
          >
            <DynamicAssetComponent
              name={ResellerConfig.getAssetPath('logo')}
              height={44}
              width={152}
            />
          </BackgroundImage>
        ) : (
          <ImageWrapper data-test='layout-image'>
            <DynamicAssetComponent
              name={ResellerConfig.getAssetPath('logo')}
              height={44}
              width={152}
            />
            <DynamicAssetComponent name={ResellerConfig.getAssetPath('login_hero')} />
          </ImageWrapper>
        )}
      </LeftWrapper>
      <RightWrapper
        flex='1 1 50%'
        alignItems='center'
        justifyContent='center'
        data-test='authlayout-rightwrapper'
      >
        {children}
      </RightWrapper>
    </LayoutWrapper>
  );
}
