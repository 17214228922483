export enum CRITICAL_ACTION {
  UPDATE_EMAIL = 'UPDATE_EMAIL',
  ADD_PAYMENT_CARD = 'ADD_PAYMENT_CARD',
}

export enum SUCCEED_ACTION {
  NEW_PASSWORD = 'new_password',
  UPDATE_EMAIL = 'update_email',
  UPDATE_EMAIL_VERIFIED = 'update_email_verified',
}

export enum FAILED_ACTION {
  TTL = 'ttl',
  UNKNOWN = 'unknown',
}
