import { gql } from '@apollo/client';

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation($input: ResetPasswordMutationInput!) {
    resetPassword(input: $input)
      @rest(
        type: "MutationSuccessResponse"
        path: "auth/v1/users/password/reset"
        method: "POST"
        endpoint: "authentication"
        bodySerializer: "auth"
      ) {
      _
    }
  }
`;
