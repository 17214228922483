import { useResetPassword } from './hooks/useResetPassword';

import { LoginLayout } from '@components/LoginLayout/LoginLayout';
import { PasswordForm } from '@components/PasswordForm/PasswordForm';
import { RequireUrlParams } from '@components/RequireUrlParams';
import { LOGIN_ROUTE } from '@constants/routes.constants';
import { useTranslation } from 'react-i18next';

export default function ResetPasswordPage() {
  const { t } = useTranslation();
  const { handleResetPassword, isResetting } = useResetPassword();

  return (
    <RequireUrlParams params={['reset_password_token', 'email']} redirectTo={LOGIN_ROUTE}>
      <LoginLayout data-test='reset-password-page'>
        <PasswordForm
          title={t('reset_password.title')}
          onSubmit={handleResetPassword}
          disabled={isResetting}
          buttonText={t('password_form.submit.label')}
        />
      </LoginLayout>
    </RequireUrlParams>
  );
}
