import styled from 'styled-components';

import { PasswordFormProps } from './PasswordForm.decl';

import {
  Banner,
  BannerHeading,
  BannerIcon,
  Button,
  Form,
  Spacer,
  Typography,
  Box,
} from '@aircall/tractor-v2';
import { ReactComponent as AircallLogo } from '@assets/images/aircall_logo.svg';
import { ExternalLink } from '@components/Link';
import { ButtonWrapper, FormWrapper } from '@components/LoginLayout/Login.styles';
import { PasswordRequirements } from '@components/PasswordRequirements';
import { DESKTOP_DEVICE } from '@constants/responsive.contants';
import {
  FormField,
  Gap,
  pipeValidators,
  validateConfirmedPassword,
  validatePasswordLength,
  validatePasswordStrength,
  validateRequired,
} from '@dashboard/library';
import { AnimatePresence } from 'framer-motion';
import { Form as RFForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const AircallLogoWrapper = styled(Box)`
  @media (max-width: ${DESKTOP_DEVICE}) {
    display: block;
  }
`;

export function PasswordForm({
  title,
  success,
  errorMessage,
  onSubmit,
  disabled = false,
  buttonText,
}: PasswordFormProps) {
  const { t } = useTranslation();

  return (
    <FormWrapper>
      <RFForm
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          pristine,
          submitting,
          hasValidationErrors,
          submitError,
          values,
          touched,
        }) => {
          const shouldDisableSubmit = pristine || hasValidationErrors || submitting || disabled;
          return (
            <Form onSubmit={handleSubmit}>
              <Gap flexDirection='column' alignItems='center' gap='s'>
                <AircallLogoWrapper display='none'>
                  <AircallLogo />
                </AircallLogoWrapper>
                <Spacer direction='vertical' space='m' fluid>
                  {title && (
                    <Typography variant='headingBoldM' textAlign='center'>
                      {title}
                    </Typography>
                  )}
                  {success && (
                    <Banner variant='success' data-test='success-banner' inline>
                      <BannerIcon />
                      <BannerHeading>{t('confirm_invitation.success.create')}</BannerHeading>
                    </Banner>
                  )}
                  {errorMessage && (
                    <Banner variant='critical' inline>
                      <BannerIcon />
                      <BannerHeading>{errorMessage}</BannerHeading>
                    </Banner>
                  )}

                  <FormField
                    name='password'
                    type='password'
                    label={t('password_form.new_password.label')}
                    placeholder={t('password_form.new_password.placeholder')}
                    validate={pipeValidators(
                      validateRequired,
                      validatePasswordLength,
                      validatePasswordStrength
                    )}
                    getErrorMessage={t}
                    disabled={disabled}
                    data-test='password-form-password'
                  />

                  <PasswordRequirements
                    password={values.password}
                    displayErrors={Boolean(touched?.password)}
                  />

                  <FormField
                    name='passwordConfirmation'
                    type='password'
                    label={t('password_form.confirm_password.label')}
                    placeholder={t('password_form.confirm_password.placeholder')}
                    validate={validateConfirmedPassword('password')}
                    getErrorMessage={t}
                    disabled={disabled}
                    data-test='password-form-password-confirmation'
                  />
                </Spacer>
              </Gap>
              <ButtonWrapper>
                <Spacer direction='vertical' space='m' fluid>
                  <AnimatePresence>
                    {submitError && (
                      <Banner variant='critical' inline>
                        <BannerIcon />
                        <BannerHeading>{t(submitError)}</BannerHeading>
                      </Banner>
                    )}
                  </AnimatePresence>
                  <Button
                    type='submit'
                    data-test='password-form-submit'
                    {...(!shouldDisableSubmit && {
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      'data-tracking': 'password_update_submitted',
                    })}
                    disabled={shouldDisableSubmit}
                    size='large'
                    block
                  >
                    {buttonText || t('generic.confirm')}
                  </Button>
                  <ExternalLink
                    href='https://help.aircall.io'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Typography textAlign='center' variant='bodyMediumS'>
                      {t('password_form.link.need_help')}
                    </Typography>
                  </ExternalLink>
                </Spacer>
              </ButtonWrapper>
            </Form>
          );
        }}
      />
    </FormWrapper>
  );
}
