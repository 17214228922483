import { useCallback } from 'react';

import { PasswordFormValues } from '@components/PasswordForm/PasswordForm.decl';
import { SUCCEED_ACTION } from '@constants/critical-action.constants';
import { ACTION_RESULT_ROUTE } from '@constants/routes.constants';
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from '@generated/ResetPasswordMutation';
import { RESET_PASSWORD_MUTATION } from '@graphql/mutations/ResetPasswordMutation';
import { ClientException, GenericFormError, handleFormErrors } from '@helpers/errors.helpers';
import { useGraphMutation } from '@hooks/useMutation';
import { useQueryParams } from '@hooks/useQueryParams';
import { useNavigate, createSearchParams } from 'react-router-dom';

interface UseResetPasswordReturnType {
  handleResetPassword: ({
    password,
    passwordConfirmation,
  }: PasswordFormValues) => Promise<Record<string, string> | GenericFormError | null>;
  isResetting: boolean;
}

export function useResetPassword(): UseResetPasswordReturnType {
  const navigate = useNavigate();
  const { searchParams } = useQueryParams();
  const [resetPassword, { loading: isResetting }] = useGraphMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(RESET_PASSWORD_MUTATION, {
    onCompleted: () => {
      navigate({
        pathname: ACTION_RESULT_ROUTE,
        search: `?${createSearchParams({ action: SUCCEED_ACTION.NEW_PASSWORD })}`,
      });
    },
  });

  const handleResetPassword = useCallback(
    async ({ password, passwordConfirmation }: PasswordFormValues) => {
      try {
        const confirmationCode = searchParams.get('reset_password_token')!;
        const email = searchParams.get('email')!;

        await resetPassword({
          variables: {
            input: {
              newPassword: password,
              confirmationPassword: passwordConfirmation,
              confirmationCode,
              email,
            },
          },
        });
        return null;
      } catch (error) {
        return handleFormErrors(error as ClientException, {
          keyMapper: { name: '', message: 'error' },
          validationErrorAsFormError: true,
        });
      }
    },
    [resetPassword, searchParams]
  );

  return { handleResetPassword, isResetting };
}
