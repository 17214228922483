import { PasswordRequirementsProps } from './PasswordRequirements.decl';

import { CloseOutlined, TickOutlined } from '@aircall/icons';
import { Spacer, Typography } from '@aircall/tractor-v2';
import { useTranslation } from 'react-i18next';

export function PasswordRequirements({
  password,
  displayErrors = true,
}: PasswordRequirementsProps) {
  const { t } = useTranslation();

  const requirements = [
    {
      validate: (str: string) => /.{8,}/.test(str), // at least 8 characters
      text: t('password_requirements.height_characters'),
    },
    {
      validate: (str: string) => /[a-z]+/.test(str), // at least 1 lower case letter
      text: t('password_requirements.one_lowercase'),
    },
    {
      validate: (str: string) => /[A-Z]+/.test(str), // at least 1 upper case letter
      text: t('password_requirements.one_uppercase'),
    },
    {
      validate: (str: string) => /\d+/.test(str), // at least 1 number
      text: t('password_requirements.one_number'),
    },
    {
      validate: (str: string) => /[#?!@$%^&+=*-]+/.test(str), // at least 1 special character
      text: t('password_requirements.one_special_character'),
    },
  ];

  const requirementsList = requirements.map(({ validate, text }) => {
    const isValid = password ? validate(password) : false;

    let color = 'primary-300';
    let Icon = TickOutlined;
    if (!isValid) {
      if (displayErrors) {
        color = 'critical-600';
        Icon = CloseOutlined;
      } else {
        color = 'neutral-1000';
      }
    }

    return (
      <Spacer key={text} direction='horizontal' alignItems='center' space='xxs'>
        <Icon data-test={`icon-${text}`} color={color} size={18} />
        <Typography position='relative' top='1px' left='-2px' variant='bodyRegularS' color={color}>
          {text}
        </Typography>
      </Spacer>
    );
  });

  return (
    <Spacer direction='vertical' data-test='password-requirements'>
      <Typography variant='bodyRegularS' color='text.dark'>
        {t('password_requirements.title')}
      </Typography>
      <Spacer direction='vertical' space={0}>
        {requirementsList}
      </Spacer>
    </Spacer>
  );
}
